<template>
  <van-form ref="form" validate-first @failed="onFailed" @submit="onSubmit">
    <!-- 通过 pattern 进行正则校验 -->
    <van-field
        v-model="dataForm.confirmedBy"
        name="pattern"
        label="确认人"
        placeholder="确认人"
    />
<!--    :rules="[{ pattern, message: '请输入正确内容' }]"-->
    <!-- 通过 validator 进行函数校验 -->
    <van-field
        v-model="dataForm.confirmedPhone"
        name="validator"
        label="确认电话"
        placeholder="确认电话"
    />
<!--    :rules="[{ validator, message: '请输入正确电话' }]"-->
    <van-field name="uploader" label="确认凭证">
      <template #input>
        <van-uploader v-model="uploader"
                      :after-read="afterRead"
                      :max-count="1"
                      :preview-image="true"/>
      </template>
    </van-field>
    <div style="margin: 16px;">
      <van-button round block type="info" native-type="submit">提交</van-button>
    </div>
  </van-form>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      uploader: [],
      dataForm: {
        deliveryNoteId:0,
        confirmedBy: '',
        confirmedPhone: '',
        confirmedVoucher:'',
      }
    }
  },
  created() {
    let deliveryNoteId = this.$route.params.deliveryNoteId;
    this.dataForm.deliveryNoteId = deliveryNoteId;
  },
  methods: {
    onFailed(errorInfo) {
      console.log(errorInfo);
    },
    validator( value) {
      return /^[A-Za-z\\u4e00-\\u9fa5]{2,20}$/.test(value);
    },
    pattern( value) {
      return /^1[34578]\d{9}$/.test(value);
    },
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      // let uploader = this.dataForm.uploader;
      // let imgs = [];
      // uploader.forEach((item) => {
      //   let img = new Image()
      //   img.src = item.content
      //   imgs.push(compressImg(img))
      // })
      // let imgFile = dataURLtoFileFun(imgs[0],file.file.name);
      let form = new FormData();
      form.append('file', file.file);
      this.$http.post(this.baseMallUrl + `/h5_mall/upload`, form,
          {headers:{"content-type":  "multipart/form-data"}}).then((res) => {
        if (res.data) {
          file.status = "done";
          file.message = "上传完成";
          this.uploadLoading = false;
          this.dataForm.confirmedVoucher = res.data.url;
        } else {
          file.status = "failed";
          file.message = "上传失败";
          this.uploadLoading = false;
          this.$toast(res.data);
        }
      });
    },
    onSubmit(){
      this.$dialog.confirm({
        title: '提示',
        message: `确认提交`,
      }).then(() => {
        // TODO SEVER
        let data = this.dataForm;
        this.$http.post(this.baseMallUrl + `/h5_mall_order/updateDeliveryNote`, data, {emulateJSON: true}).then((res) => {
          if (res.data) {
            this.$dialog.alert({
              title: '标题',
              message: '送货单确认完成',
            }).then(() => {
              window.close();
            });
          } else {
            this.$toast(res.message);
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="less">

</style>
